import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Roles } from '../enums/roles';

@Injectable({
  providedIn: 'root',
})
export class NoAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate() {
    let user = await this.authService.getLoggedUserFromLocalStorage();
    if (!!user && user.uuid && user.role !== Roles.ADMIN) {
      return true;
    } else {
      this.router.navigate(['backoffice']);
      return false;
    }
  }
}

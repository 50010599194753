import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    const activeLanguage = localStorage.getItem('lang');
    if (activeLanguage) {
      return this.http.get<Translation>(
        `../assets/i18n/${activeLanguage}.json`,
      );
    } else {
      return this.http.get<Translation>(`../assets/i18n/${lang}.json`);
    }
  }
}

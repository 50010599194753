import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public STORAGE_NAME = 'user';
  apiUrl = environment.apiUrl;
  public userDocumentation: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) {}

  getUserDocumentation(uuid: string) {
    return this.http.get<any>(`${this.apiUrl}/user-documentation/${uuid}`);
  }

  getUserProfile(uuid: string) {
    return this.http.get<any>(`${this.apiUrl}/users/profile/${uuid}`);
  }

  updateProfile(uuid: string, body: any) {
    return this.http
      .put<any>(`${this.apiUrl}/users/update-user/${uuid}`, body)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () =>
              new Error(error.error.message || 'An unexpected error occurred'),
          );
        }),
      );
  }

  getUserLevel(uuid: string) {
    return this.http.get<any>(`${this.apiUrl}/user-level/user/${uuid}`);
  }

  changePassword(request: any) {
    return this.http.post<any>(`${this.apiUrl}/auth/change-password`, request);
  }

  getUserAssets(userId: string) {
    return this.http.get<any>(`${this.apiUrl}/users/assets/${userId}`);
  }

  //BACKOFFICE
  getAllUsers(search?: string) {
    const params = search ? `?search=${encodeURIComponent(search)}` : '';
    return this.http.get(`${this.apiUrl}/backoffice/users${params}`);
  }

  getUserBackofficeById(uuid: string) {
    return this.http.get(`${this.apiUrl}/backoffice/users/${uuid}`);
  }

  updateUserLevel(body: any) {
    return this.http.put(`${this.apiUrl}/user-kyc/update-user-level`, body);
  }

  blockUser(body: any){
    return this.http.post(`${this.apiUrl}/backoffice/users/block`, body)
  }
}

import { enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  provideRouter,
  withPreloading,
  PreloadAllModules,
} from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { KycService } from './app/services/kyc.service';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { IonicService } from './app/services/ionic.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from './app/services/auth.service';
import { UserService } from './app/services/user.service';
import { RequestInterceptor } from './app/core/guards/request.interceptor';
import { provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './app/transloco-root.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';

//This is necessary for the thousands separator to be a "."
registerLocaleData(localeEsAr, 'es-AR');

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    KycService,
    HttpClient,
    BrowserModule,
    IonicService,
    ModalController,
    BrowserModule,
    HttpClientModule,
    provideHttpClient(withInterceptorsFromDi()),
    AuthService,
    UserService,
    IonicService,
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es'],
        defaultLang: 'es',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    { provide: LOCALE_ID, useValue: 'es-AR' },
  ],
});
import { Routes } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';
import { NoAdminGuard } from './core/guards/no-admin.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile-routing.module').then(
        (m) => m.ProfilePageRoutingModule,
      ),
    canActivate: [NoAdminGuard],
  },
  {
    path: 'backoffice',
    loadChildren: () =>
      import('./pages/backoffice/backoffice-routing.module').then(
        (m) => m.BackofficePageRoutingModule,
      ),
    canActivate: [AdminGuard],
  },
  {
    path: 'details/:id',
    loadComponent: () =>
      import('./pages/details/details.page').then((m) => m.DetailsPage),
    canActivate: [NoAdminGuard],
  },
  {
    path: 'details/:id/:marketplaceId',
    loadComponent: () =>
      import('./pages/details/details.page').then((m) => m.DetailsPage),
    canActivate: [NoAdminGuard],
  },
  {
    path: 'my-properties',
    loadComponent: () =>
      import('./pages/my-properties/my-properties.page').then(
        (m) => m.MyPropertiesPage,
      ),
    canActivate: [NoAdminGuard],
  },
  {
    path: 'auth/password-reset',
    loadChildren: () =>
      import('./pages/auth/pasword-reset/pasword-reset-routing.module').then(
        (m) => m.PaswordResetPageRoutingModule,
      ),
  },
  {
    path: 'auth/account-ready',
    loadChildren: () =>
      import('./pages/auth/email-confirm/email-cofirm-routing.module').then(
        (m) => m.EmailConfirmPageRoutingModule,
      ),
  },
  {
    path: 'email-confirm',
    loadComponent: () =>
      import('./pages/auth/email-confirm/email-confirm.page').then(
        (m) => m.EmailConfirmPage,
      ),
  },
  {
    path: 'my-account',
    loadComponent: () => 
      import('./pages/my-account/my-account.page').then((m) => m.MyAccountPage),
    canActivate: [NoAdminGuard],
  },
  {
    path: 'marketplace',
    loadComponent: () => 
      import('./pages/marketplace/marketplace.page').then( m => m.MarketplacePage)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

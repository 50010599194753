import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KycService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  public getKycByStatus(status: string, pagination: any, searchText?: string): Observable<any> {
    let params = new HttpParams()
      .set('status', status)
      .set('page', pagination.page)
      .set('take', pagination.take)

    if (searchText) {
      params = params.set('searchText', searchText);
    }
    
    return this.http.get<any>(`${this.apiUrl}/user-kyc/get-by-status`, { params });
  }

  updateUserDocumentation(id: string, data: any) {
    // let user = JSON.parse(localStorage.getItem('user')!);
    return this.http.put<any>(`${this.apiUrl}/user-kyc/update-user-documentation/${ id }`, data);
  }
}

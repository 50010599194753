export const environment = {
  production: true,
  apiUrl: 'https://gbe5q72m7dy3rd3x2676y6hxpi0ztpnj.lambda-url.us-east-1.on.aws/',
  // apiUrl: 'http://localhost:3000',
  geoApi: '',
  mapEndpoint: 'https://maps.googleapis.com/maps/api/js',
  mapKey: 'AIzaSyBxbjK-hWHC35NMJGTvvXV5L8BWMVivItg',
  MUMBAI_LINK: 'https://mumbai.polygonscan.com/',
  urlImages: 'https://efaysto-dev-20240708151619876300000001.s3.us-east-1.amazonaws.com/',
  efaystoWallet: '0x739cBaD141A01D07b28135D8ED2F91dd39096882'
};
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { ModalController, ToastController } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root'
})

export class IonicService {
    LOADING: any;
    alert: any;

    constructor(
        private router: Router,
        private loadingController: LoadingController,
        private alertController: AlertController,
        private modalController: ModalController,
        private toastController: ToastController,
    ) { }

    /**
     *
     * @param modalPage
     * @param cssClass
     * @param componentProps
     * @param allowDismiss
     * @returns *for more info https://ionicframework.com/docs/api/modal
     *
     *
     */

    async showModal(
        modalPage: any,
        cssClass = [''],
        componentProps?: any,
        allowDismiss?: boolean
    ) {
        const modal = await this.modalController.create({
        component: modalPage,
        componentProps,
        cssClass,
        backdropDismiss: allowDismiss != null ? allowDismiss : true
        });
        await modal.present();
        return modal;
    }


    goPage(page: string, param?: string) {
        const urlParam = !!param ? param : '';
        this.router.navigate([`${page}/${urlParam}`], { replaceUrl: true });
    }

    async showAlert(
        header: string,
        message: string,
        buttons?: any[],
        inputs?: any[],
        id?: string
    ) {
        if (this.alert.length > 0) {
            this.alert.forEach(async (alert: any) => {
                await alert.dismiss();
            });
            this.alert = [];
        }
        const alert = await this.alertController.create({
            mode: 'ios',
            header,
            message,
            id: id ?? 'alert',
        });
        this.alert.push(alert);

        if (Boolean(buttons?.length) && buttons) {
            alert.buttons = buttons;
        }

        if (Boolean(inputs?.length) && inputs) {
            alert.inputs = inputs;
        }

        await alert.present();

        return alert.onDidDismiss();
    }

    /**
     * dismisses the current alert
     */
    dismissModal(data?: unknown) {
        this.modalController.dismiss(data ?? null);
    }

    dismissAlert() {
        this.alertController.dismiss();
    }

    /**
     * instantiates an ionic loading component
     *
     * @param message loading text / defaults to 'Cargando...'
     * @param duration duration / default 1000ms
     */

    async showLoading(message?: string, duration?: number) {
        this.LOADING = await this.loadingController.create({
            message: message || 'Cargando',
            spinner: 'bubbles',
        });

        if (Boolean(duration)) {
            this.LOADING.duration = duration;
        }

        await this.LOADING.present();
    }

    /**
     * dismisses the current loading component
     */

    async dismissLoading() {
        if (this.LOADING) {
            await this.LOADING.dismiss();
        }
    }
    
    /**
     *
     * @param type type of toast
     * @param message message to display in the toast
     * @param duration duration / default to 2000ms
     */

    async showToast(message: string, type: 'success' | 'error', duration = 3000) {
        const toast = await this.toastController.create({
        position: 'top',
        message,
        duration,
        cssClass: ['toast-item', `toast-${type}`],
        icon: type === 'success' ? 'assets/icon/toast-success.svg' : 'assets/icon/toast-error.svg',
        color: 'unset',
        });
        toast.present();
    }

}
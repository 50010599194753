
import { inject } from "@angular/core";
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { User } from "../models/user.model"; // Asegúrate de tener este modelo
import { AuthService } from "../services/auth.service"; // Asegúrate de tener este servicio
import to from "await-to-js";
import { lastValueFrom } from "rxjs";
import { isTokenExpired } from '../core/utils/is-token-expired'; // Asegúrate de tener esta utilidad

type UserState = {
    user: User | null;
    tokens: string | null;
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    user: null,
    tokens: null,
    loading: false,
    error: null,
}

function loadUserFromLocalStorage(): UserState {
    const user = localStorage.getItem('user');
    const tokens = localStorage.getItem('tokens');
    if (user && tokens) {
        if (isTokenExpired(tokens)) {
            localStorage.removeItem('user');
            localStorage.removeItem('tokens');
            return initialState;
        }
        return {
            user: JSON.parse(user),
            tokens,
            loading: false,
            error: null,
        };
    }
    return initialState;
}
export const UserStore = signalStore(
    { providedIn: 'root' },
    withState(loadUserFromLocalStorage()),
    withMethods(
        (store: any, authService = inject(AuthService)) => ({
            async login(email: string, password: string) {
                patchState(store, { loading: true, error: null });

                const [err, response] = await to(lastValueFrom(authService.login({ email, password })));

                if (err) {
                    patchState(store, { error: err.message, loading: false });
                    return;
                }

                if (response) {
                    const { user, tokens } = response;
                    patchState(store, { user, tokens, loading: false });

                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('tokens', tokens);
                } else {
                    patchState(store, { error: 'Login failed', loading: false });
                }
            },

            async logOut() {
                patchState(store, {
                    user: null,
                    tokens: null,
                    error: null,
                    loading: false
                });

                localStorage.removeItem('user');
                localStorage.removeItem('tokens');
            },

            updateUser(updatedUser: User) {
                console.log('updateStateUser', updatedUser);
                patchState(store, { user: updatedUser, loading: false });
                localStorage.setItem('user', JSON.stringify(updatedUser));
            }
        })
    )
);


console.log('!!!!!!!!!! ENTRA EN EL STORAGE !!!!!!!!!')
